import "react-widgets/styles.css";
import "react-select-search/style.css";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Layout from "../../components/layouts/Layout";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetchObject, updateObject } from "../../Api";
import PageHeader from "../../components/PageHeader";

export default function CustomerEditor() {
  const { id } = useParams();

  const navigate = useNavigate();

  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const maleRef = useRef();
  const femaleRef = useRef();
  const activeRef = useRef();

  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // e.stopPropagation();

    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity()) {
      const data = {
        id: user.id,
        email: emailRef.current.value,
        firstname: firstnameRef.current.value,
        lastname: lastnameRef.current.value,
        gender: maleRef.current.checked ? "M" : "F",
        active: activeRef.current.checked,
        password: passwordRef.current.value,
      };

      try {
        const response = await updateObject("customers", data);

        if (response != null) {
          navigate(`/customers/${response.id}`);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (id && parseInt(id) > 0) {
      setEditMode(true);
      fetchObject("customers", id)
        .then((result) => {
          if (result.data.account_type === 1) {
            navigate("/customers");
          }
          setUser(result.data);
          return result.data;
        })
        .catch(console.error);
    } else {
      setUser({
        id: null,
        email: null,
        password: null,
        firsname: null,
        lastname: null,
        gender: "M",
        active: true,
      });
    }
  });

  const handlePasswordInput = (event) => {
    const target = event.target;
    const password_pattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/;

    if (editMode && target.value.length === 0) {
      target.setCustomValidity("");
      return;
    }

    if (!password_pattern.test(target.value)) {
      target.setCustomValidity("كلمة المرور غير مقبولة");
      target.reportValidity();
    } else {
      target.setCustomValidity("");
    }
  };

  return user ? (
    <Layout>
      <PageHeader title={`${editMode ? "تعديل" : "إضافة"} عميل`} />
      <Container>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <input type="hidden" name="id" value={user.id} id="id" />
          <Form.Group className="mb-3" controlId="formUserEmail">
            <Form.Label>البريد الإلكتروني</Form.Label>
            <Form.Control
              type="email"
              required={true}
              readOnly={editMode}
              defaultValue={user.email}
              ref={emailRef}
              placeholder="أدخل البريد الإلكتروني"
            />
            <Form.Control.Feedback type="invalid">
              يرجى إدخال البريد الإلكتروني
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="formUserPassword"
            onInput={handlePasswordInput}
          >
            <Form.Label>كلمة المرور</Form.Label>
            <Form.Control
              type="password"
              required={!editMode}
              minLength={8}
              maxLength={32}
              defaultValue={user.password}
              ref={passwordRef}
              placeholder="ادخل كلمة المرور"
            />
            <Form.Text>
              {editMode && (
                <>
                  <span>Enter only if you want to change it</span>
                  <br />
                </>
              )}
              Password must be 8-32 characters long, must contain special
              characters &quot;!@#$%&*_?&quot;, numbers, lower and upper letters
              only.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formUserFirstName">
            <Form.Label>الاسم الأول</Form.Label>
            <Form.Control
              type="text"
              required={true}
              minLength={2}
              maxLength={40}
              defaultValue={user.firstname}
              ref={firstnameRef}
              placeholder="أدخل الاسم الأول"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formUserLastName">
            <Form.Label>اسم العائلة</Form.Label>
            <Form.Control
              type="text"
              required={true}
              minLength={2}
              maxLength={40}
              defaultValue={user.lastname}
              ref={lastnameRef}
              placeholder="أدخل اسم العائلة"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formUserGender">
            <Form.Label>النوع</Form.Label>
            <Form.Check
              reverse
              label="ذكر"
              name="gender"
              type="radio"
              valuse="M"
              id="genderM"
              defaultChecked={user.gender === "M"}
              ref={maleRef}
            />
            <Form.Check
              reverse
              label="أنثى"
              name="gender"
              type="radio"
              value="F"
              id="genderF"
              defaultChecked={user.gender === "F"}
              ref={femaleRef}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formUserActive">
            <Form.Label>مفعل</Form.Label>
            <Form.Check
              type="checkbox"
              ref={activeRef}
              defaultChecked={user.active}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="ms-3">
            حـفـظ
          </Button>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            إلغاء الأمر
          </Button>
        </Form>
        <p></p>
        <p></p>
      </Container>
    </Layout>
  ) : (
    <></>
  );
}
