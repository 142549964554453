import React from "react";
import PropTypes from "prop-types";

const PageHeader = (props) => {
  PageHeader.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
  };

  return (
    <div className="page-header">
      <h1>{props.title}</h1>
      {props.children && <div>{props.children}</div>}
    </div>
  );
};

export default PageHeader;
