import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks";
import Layout from "../../components/layouts/Layout";
import { fetchObject } from "../../Api";
import { Card, Col, Row } from "react-bootstrap";

const Home = () => {
  const navigate = useNavigate();
  const [books, setBooks] = useState(0);
  const [authors, setAuthors] = useState(0);
  const [editors, setEditors] = useState(0);
  const [translators, setTranslators] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [orders, setOrders] = useState(0);
  const [sales, setSales] = useState(0);

  useEffect(() => {
    fetchObject("stats")
      .then((result) => {
        setBooks(result.data.books);
        setAuthors(result.data.authors);
        setEditors(result.data.editors);
        setTranslators(result.data.translators);
        setCustomers(result.data.customers);
        setOrders(result.data.orders);
        setSales(result.data.sales);
      })
      .catch(console.error);
  });

  return (
    <Layout>
      <div className="page-header">
        <h1>لوحة المعلومات</h1>
      </div>
      <Row className="mb-4">
        <Col md="3">
          <Card
            style={{ width: "18rem", cursor: "pointer" }}
            onClick={() => navigate("/books")}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>الكتب</Card.Title>
              <Card.Text
                className="py-3"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
              >
                {books}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3">
          <Card
            style={{ width: "18rem", cursor: "pointer" }}
            onClick={() => navigate("/authors")}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>المؤلفون</Card.Title>
              <Card.Text
                className="py-3"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
              >
                {authors}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3">
          <Card
            style={{ width: "18rem", cursor: "pointer" }}
            onClick={() => navigate("/editors")}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>المحققون</Card.Title>
              <Card.Text
                className="py-3"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
              >
                {editors}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3">
          <Card
            style={{ width: "18rem", cursor: "pointer" }}
            onClick={() => navigate("/translators")}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>المترجمون</Card.Title>
              <Card.Text
                className="py-3"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
              >
                {translators}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <Card
            style={{ width: "18rem", cursor: "pointer" }}
            onClick={() => navigate("/customers")}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>العملاء</Card.Title>
              <Card.Text
                className="py-3"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
              >
                {customers}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3">
          <Card
            style={{ width: "18rem", cursor: "pointer" }}
            onClick={() => navigate("/invoices")}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>الطلبات</Card.Title>
              <Card.Text
                className="py-3"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
              >
                {orders}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3">
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>المبيعات</Card.Title>
              <Card.Text
                className="py-3"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
              >
                {sales.toFixed(3)}
                &nbsp;د.ك
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default Home;
