import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from "../NavigationBar";
import PropTypes from "prop-types";
import SideBar from "../SideBar";

const Layout = ({ children }) => {
  Layout.propTypes = {
    children: PropTypes.array,
  };

  return (
    <Container fluid>
      <Row className="row flex-nowrap">
        <Col className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
          <SideBar />
        </Col>
        <Col className="col-auto col-md-9 col-xl-10 px-sm-2 px-0">
          <Row className="row flex-nowrap">
            <Col className="col-auto col-md-12 col-xl-12 bg-dark">
              <NavigationBar />
            </Col>
          </Row>
          <Row>
            <Col className="col-auto col-md-12 col-xl-12">{children}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
