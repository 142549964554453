import "../../App.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import { fetchObject } from "../../Api";
import { Table } from "react-bootstrap";
import PersonBooksList from "../../components/PersonBooksList";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function TranslatorDetails() {
  const { id } = useParams();

  const [translator, setTranslator] = useState(null);

  useEffect(() => {
    fetchObject("translators", id)
      .then((result) => {
        setTranslator(result.data);
        return result;
      })
      .catch(console.error);
  }, [id]);

  return (
    <Layout>
      <div className="page-header">
        <h1>المترجم</h1>
      </div>
      {translator ? (
        <>
          <div className="action-bar">
            <Link to={"/translators/edit/" + translator.id}>تعديل</Link>
          </div>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>المعرف:</td>
                <td>{translator.id}</td>
              </tr>
              <tr>
                <td>الاسم:</td>
                <td>{translator.name}</td>
              </tr>
            </tbody>
          </Table>
          <PersonBooksList books={translator.books} />
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}
