import "../../App.css";
import React, { useState, useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import { useNavigate } from "react-router";
import PageHeader from "../../components/PageHeader";
import ActionButton from "../../components/ActionButton";
import DataGrid from "../../components/DataGrid";
import { deleteObject, fetchCollection } from "../../Api";
import LoadingIndicator from "../../components/LoadingIndicator";

const Users = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchCollection("users", [], [])
      .then((result) => {
        setData(result);
        return result;
      })
      .catch(console.error);
  }, []);

  const columns = [
    {
      dataField: "id",
      text: "المعرف",
      sort: true,
      style: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      headerStyle: (colum, colIndex) => {
        return {
          width: "100px",
          minWidth: "100px",
          textAlign: "center",
        };
      },
    },
    {
      dataField: "username",
      text: "اسم المستخدم",
      sort: true,
    },
    {
      dataField: "firstname",
      text: "الاسم الأول",
      sort: true,
    },
    {
      dataField: "lastname",
      text: "الاسم الأخير",
      sort: true,
    },
    {
      dataField: "email",
      text: "البريد الإلكتروني",
      sort: true,
    },
    {
      dataField: "role.name",
      text: "الوظيفة",
      sort: true,
    },
  ];

  const handleDelete = (row) => {
    // Make sure we dont delete the default admin account
    if (row.id > 1) {
      deleteObject("users", row.id)
        .then((result) => {
          if (result === 1) {
            window.location.reload();
          }
        })
        .catch(console.error);
    }
  };

  return (
    <Layout>
      <PageHeader title="المستخدمون">
        <ActionButton
          title="إضافة"
          className="action-button green-button"
          onClick={() => navigate("/users/edit/0")}
        />
      </PageHeader>
      {data ? (
        <DataGrid
          data={data}
          columns={columns}
          onView={(row) => navigate(`/users/${row.id}`)}
          canEdit={(row) => true}
          onEdit={(row) => navigate(`/users/edit/${row.id}`)}
          onDelete={(row) => handleDelete(row)}
          canDelete={(row) => row.id > 1}
        />
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
};

export default Users;
