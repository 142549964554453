import "../../App.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import { fetchObject } from "../../Api";
import { Table } from "react-bootstrap";

export default function BannerDetails() {
  const { id } = useParams();
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    fetchObject("banners", id)
      .then((result) => {
        setBanner(result.data);
        return result.data;
      })
      .catch(console.error);
  }, [id]);

  return (
    <Layout>
      <div className="page-header">
        <h1>الإعلان</h1>
      </div>
      {banner ? (
        <>
          <div className="action-bar">
            <Link to={"/banners/edit/" + banner.id}>تعديل</Link>
          </div>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>المعرف:</td>
                <td>{banner.id}</td>
              </tr>
              <tr>
                <td>الاسم:</td>
                <td>{banner.name}</td>
              </tr>
              <tr>
                <td>الإعلان:</td>
                <td>{banner.banner}</td>
              </tr>
              <tr>
                <td>الرابط:</td>
                <td>{banner.link}</td>
              </tr>
              <tr>
                <td>مفعل:</td>
                <td>{banner.active}</td>
              </tr>
            </tbody>
          </Table>
          <div style={{ textAlign: "center" }}>
            <img
              src={banner.banner}
              alt=""
              style={{ width: 600, height: 338 }}
            />
          </div>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}
