import React from "react";
import { Col } from "react-bootstrap";
import { useAuth } from "../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa0,
  faAddressBook,
  faBlackboard,
  faBook,
  faChain,
  faHome,
  faPeopleGroup,
  faPerson,
  faPersonArrowDownToLine,
  faPersonBooth,
  faPersonBurst,
  faPersonCane,
  faPersonChalkboard,
  faSort,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

const SideBar = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  if (!currentUser) {
    navigate("/login");
  }

  return (
    <Col className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <a
          href="/"
          className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <span className="fs-5 d-none d-sm-inline">
            <img
              src="/enara-logo.png"
              alt="إنارة"
              style={{ maxHeight: "40px" }}
            />
          </span>
        </a>
        <ul
          className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
        >
          <li className="nav-item">
            <a href="/" className="nav-link align-middle px-0">
              <FontAwesomeIcon icon={faHome} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">الرئيسة</span>
            </a>
          </li>
          <li>
            <a
              href="/books"
              data-bs-toggle="collapse"
              className="nav-link px-0 align-middle"
            >
              <FontAwesomeIcon icon={faBook} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">الكتب</span>
            </a>
          </li>
          <li>
            <a href="/series" className="nav-link px-0 align-middle">
              <FontAwesomeIcon icon={faChain} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">السلاسل</span>
            </a>
          </li>
          <li>
            <a href="/categories" className="nav-link px-0 align-middle">
              <FontAwesomeIcon icon={faSort} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">التصنيفات</span>
            </a>
          </li>
          <li>
            <a href="/authors" className="nav-link px-0 align-middle">
              <FontAwesomeIcon icon={faPersonChalkboard} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">المؤلفون</span>
            </a>
          </li>
          <li>
            <a href="/editors" className="nav-link px-0 align-middle">
              <FontAwesomeIcon icon={faPersonChalkboard} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">المحققون</span>
            </a>
          </li>
          <li>
            <a
              href="/translators"
              className="nav-link px-0 align-middle"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Dashboard"
            >
              <FontAwesomeIcon icon={faPersonChalkboard} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">المترجمون</span>
            </a>
          </li>
          <li>
            <a href="/banners" className="nav-link px-0 align-middle">
              <FontAwesomeIcon icon={faBlackboard} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">الإعلانات</span>
            </a>
          </li>
          <li>
            <a href="/customers" className="nav-link px-0 align-middle">
              <FontAwesomeIcon icon={faPeopleGroup} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">العملاء</span>
            </a>
          </li>
          <li>
            <a href="/invoices" className="nav-link px-0 align-middle">
              <FontAwesomeIcon icon={faTable} />
              &nbsp;
              <span className="ms-1 d-none d-sm-inline">المبيعات</span>
            </a>
          </li>
          {currentUser && currentUser.role_id === 1 && (
            <>
              <li>
                <a href="/users" className="nav-link px-0 align-middle">
                  <FontAwesomeIcon icon={faAddressBook} />
                  &nbsp;
                  <span className="ms-1 d-none d-sm-inline">المستخدمون</span>
                </a>
              </li>
              <li>
                <a href="/roles" className="nav-link px-0 align-middle">
                  <FontAwesomeIcon icon={faPersonBooth} />
                  &nbsp;
                  <span className="ms-1 d-none d-sm-inline">الوظائف</span>
                </a>
              </li>
            </>
          )}
        </ul>
      </div>
    </Col>
  );
};

export default SideBar;
