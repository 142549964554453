import "react-widgets/styles.css";
import "react-select-search/style.css";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Layout from "../../components/layouts/Layout";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { addObject, fetchObject, updateObject } from "../../Api";

const TranslatorEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const nameRef = useRef();

  const [editMode, setEditMode] = useState(false);
  const [translator, setTranslator] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: translator.id,
      name: nameRef.current.value,
    };

    try {
      const response = editMode
        ? await updateObject("translators", data)
        : await addObject("translators", data);

      if (response != null) {
        navigate(`/translators/${response.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id && parseInt(id) > 0) {
      setEditMode(true);
      fetchObject("translators", id)
        .then((result) => {
          setTranslator(result.data);
          return result.data;
        })
        .catch(console.error);
    } else {
      setTranslator({ id: null, name: null });
    }
  }, [id]);

  return translator ? (
    <>
      <Layout>
        <div className="page-header">
          <h1>{editMode ? "تعديل" : "إضافة"} مترجم</h1>
        </div>
        <Container>
          <Form onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={translator.id} id="id" />
            <Form.Group className="mb-3" controlId="formTranslatorName">
              <Form.Label>الاسم</Form.Label>
              <Form.Control
                type="text"
                defaultValue={translator.name}
                ref={nameRef}
                placeholder="أدخل الاسم"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="ms-3">
              حـفـظ
            </Button>
            <Button variant="secondary" onClick={() => navigate(-1)}>
              إلغاء الأمر
            </Button>
          </Form>
          <p></p>
          <p></p>
        </Container>
      </Layout>
    </>
  ) : (
    <></>
  );
};

export default TranslatorEditor;
