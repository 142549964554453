import React from "react";
import { useAuth } from "../hooks";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router";

const NavigationBar = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  if (!currentUser) {
    navigate("/login");
  }

  return (
    <Navbar bg="dark" expand="lg" className="enara-navbar">
      <Container>
        <Navbar.Brand href="#home">
          {/* <img
            src="/enara-logo.png"
            alt="إنارة"
            style={{ maxHeight: "40px" }}
          /> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <span className="navbar-text">
              مرحبا {currentUser.firstname}&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <Nav.Link href="#" onClick={logout}>
              تسجيل خروج
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
