import "react-widgets/styles.css";
import "react-select-search/style.css";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Layout from "../../components/layouts/Layout";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { addObject, fetchObject, updateObject } from "../../Api";
import PageHeader from "../../components/PageHeader";

const RoleEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const idRef = useRef();
  const nameRef = useRef();

  const [role, setRole] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: role.id,
      name: nameRef.current.value,
    };

    try {
      const response = editMode
        ? await updateObject("roles", data)
        : await addObject("roles", data);

      if (response != null) {
        navigate(`/roles/${response.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id && parseInt(id) > 0) {
      setEditMode(true);
      fetchObject("roles", id)
        .then((result) => {
          setRole(result.data);
          return result;
        })
        .catch(console.error);
    } else {
      setRole({ id: null, name: null });
    }
  }, [id]);

  return role ? (
    <Layout>
      <PageHeader title={`${editMode ? "تعديل" : "إضافة"} وظيفة`} />
      <Container>
        <Form onSubmit={handleSubmit}>
          <input type="hidden" name="id" value={role.id} ref={idRef} id="id" />
          <Form.Group className="mb-3" controlId="formRoleName">
            <Form.Label>الاسم</Form.Label>
            <Form.Control
              type="text"
              defaultValue={role.name}
              ref={nameRef}
              placeholder="أدخل الاسم"
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="ms-3">
            حـفـظ
          </Button>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            إلغاء الأمر
          </Button>
        </Form>
        <p></p>
        <p></p>
      </Container>
    </Layout>
  ) : (
    <></>
  );
};

export default RoleEditor;
