import "../../App.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import PageHeader from "../../components/PageHeader";
import ActionButton from "../../components/ActionButton";
import DataGrid from "../../components/DataGrid";
import { deleteObject, fetchCollection } from "../../Api";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function Books() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchCollection("books")
      .then((result) => {
        setData(result);
        return result;
      })
      .catch(console.error);
  }, []);

  const columns = [
    {
      dataField: "id",
      text: "المعرف",
      sort: true,
      style: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      headerStyle: (colum, colIndex) => {
        return {
          width: "100px",
          minWidth: "100px",
          textAlign: "center",
        };
      },
    },
    {
      dataField: "title",
      text: "العنوان",
      sort: true,
    },
    {
      dataField: "price",
      text: "السعر",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return {
          width: "120px",
          minWidth: "120px",
          maxWidth: "120px",
          textAlign: "center",
        };
      },
      formatter: (data, row) => data.toFixed(3),
    },
  ];

  const handleDelete = (row) => {
    deleteObject("books", row.id)
      .then((result) => {
        if (result === 1) {
          window.location.reload();
        }
      })
      .catch(console.error);
  };

  return (
    <Layout>
      <PageHeader title="الكتب">
        <ActionButton
          title="إضافة"
          className="action-button green-button"
          onClick={() => navigate("/books/edit/0")}
        />
      </PageHeader>
      {data ? (
        <DataGrid
          data={data}
          columns={columns}
          onView={(row) => navigate(`/books/${row.id}`)}
          onEdit={(row) => navigate(`/books/edit/${row.id}`)}
          onDelete={(row) => handleDelete(row)}
        />
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}
