import "../../App.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import { fetchObject } from "../../Api";
import { Table } from "react-bootstrap";

export default function BookDetails() {
  const { id } = useParams();
  const [book, setBook] = useState(null);

  useEffect(() => {
    fetchObject("books", id)
      .then((result) => {
        setBook(result.data);
        return result.data;
      })
      .catch(console.error);
  }, [id]);

  return (
    <Layout>
      <div className="page-header">
        <h1>الكتاب</h1>
      </div>
      {book ? (
        <>
          <div className="action-bar">
            <Link to={"/books/edit/" + book.id}>تعديل</Link>
          </div>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>المعرف:</td>
                <td>{book.id}</td>
                <td rowSpan="10" className="cover-pane">
                  <img
                    src={book.images[0].url}
                    alt=""
                    className="cover-thumbnail"
                  />
                </td>
              </tr>
              <tr>
                <td>الناشر:</td>
                <td>{book.publisher && book.publisher.name}</td>
              </tr>
              <tr>
                <td>السلسة:</td>
                <td>{book.series && book.series.name}</td>
              </tr>
              <tr>
                <td>العنوان:</td>
                <td>{book.title}</td>
              </tr>
              <tr>
                <td>العنوان الفرعي:</td>
                <td>{book.subtitle}</td>
              </tr>
              <tr>
                <td>تأليف:</td>
                <td>
                  {book.authors &&
                    book.authors.map((a) => (
                      <Link key={a.id} to={`/authors/${a.id}`}>
                        {a.name}
                      </Link>
                    ))}
                </td>
              </tr>
              <tr>
                <td>تحقيق:</td>
                <td>
                  {book.editors &&
                    book.editors.map((a) => (
                      <Link key={a.id} to={`/editors/${a.id}`}>
                        {a.name}
                      </Link>
                    ))}
                </td>
              </tr>
              <tr>
                <td>ترجمة:</td>
                <td>
                  {book.translators &&
                    book.translators.map((a) => (
                      <Link key={a.id} to={`/translators/${a.id}`}>
                        {a.name}
                      </Link>
                    ))}
                </td>
              </tr>
              <tr>
                <td>سنة النشر:</td>
                <td>{book.issue_year}</td>
              </tr>
              <tr>
                <td>السعر:</td>
                <td>{book.price.toFixed(3)}</td>
              </tr>
              <tr>
                <td>مفعل:</td>
                <td>{book.active ? "نعم" : "لا"}</td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}
