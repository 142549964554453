import "../../App.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import PageHeader from "../../components/PageHeader";
import { fetchObject } from "../../Api";
import { Table } from "react-bootstrap";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function CustomerDetails() {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetchObject("customers", id)
      .then((result) => {
        setUser(result.data);
        return result;
      })
      .catch(console.error);
  }, [id]);

  return (
    <Layout>
      <PageHeader title="العميل" />
      {user ? (
        <>
          <div className="action-bar">
            <Link to={"/customers/edit/" + user.id}>تعديل</Link>
          </div>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>المعرف:</td>
                <td>{user.id}</td>
              </tr>
              <tr>
                <td>البريد الإلكتروني:</td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>الاسم الأول:</td>
                <td>{user.firstname}</td>
              </tr>
              <tr>
                <td>اسم العائلة:</td>
                <td>{user.lastname}</td>
              </tr>
              <tr>
                <td>النوع:</td>
                <td>{user.gender === "M" ? "ذكر" : "إنثى"}</td>
              </tr>
              <tr>
                <td>مفعل:</td>
                <td>{user.active === 1 ? "نعم" : "لا"}</td>
              </tr>
              <tr>
                <td>تاريخ الانضمام:</td>
                <td>{user.time_created}</td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}
