import "../../App.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import { fetchObject } from "../../Api";
import { Table } from "react-bootstrap";

export default function InvoiceDetails() {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchObject("orders", id)
      .then((result) => {
        setInvoice(result.data);
        let c = 0;
        let a = 0;
        result.data.books.map((book) => {
          c += book.quantity;
          a += book.total;

          return c;
        });

        setItemsCount(c);
        setTotal(a);
        return result.data;
      })
      .catch(console.error);
  }, [id]);

  return (
    <Layout>
      <div className="page-header">
        <h1>الفاتورة</h1>
      </div>
      {invoice ? (
        <>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>المعرف:</td>
                <td>{invoice.id}</td>
              </tr>
              <tr>
                <td>رقم الفاتورة:</td>
                <td>{invoice.invoiceNo}</td>
              </tr>
              <tr>
                <td>التاريخ:</td>
                <td>{invoice.date}</td>
              </tr>
              <tr>
                <td>طريقة الدفع:</td>
                <td>{invoice.paymentMethod}</td>
              </tr>
            </tbody>
          </Table>
          <div className="page-header">
            <h1>التفاصيل</h1>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="sortable" style={{ textAlign: "center" }}>
                  الكتاب
                </th>
                <th
                  className="sortable"
                  style={{ width: "80px", textAlign: "center" }}
                >
                  الكمية
                </th>
                <th
                  className="sortable"
                  style={{ width: "100px", textAlign: "center" }}
                >
                  سعر الوحدة
                </th>
                <th
                  className="sortable"
                  style={{ width: "100px", textAlign: "center" }}
                >
                  المجموع
                </th>
              </tr>
            </thead>
            <tbody>
              {invoice.books.map((item) => (
                <tr key={item.id}>
                  <td>{item.title}</td>
                  <td style={{ width: "80px", textAlign: "center" }}>
                    {item.quantity}
                  </td>
                  <td style={{ width: "100px", textAlign: "center" }}>
                    {item.price.toFixed(3)}
                  </td>
                  <td style={{ width: "100px", textAlign: "center" }}>
                    {item.total.toFixed(3)}
                  </td>
                </tr>
              ))}
              <tr>
                <td>الإجمالي</td>
                <td style={{ width: "80px", textAlign: "center" }}>
                  {itemsCount}
                </td>
                <td>&nbsp;</td>
                <td style={{ width: "100px", textAlign: "center" }}>
                  {total.toFixed(3)}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}
