import React from "react";
import "react-widgets/styles.css";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const PersonBooksList = (props) => {
  PersonBooksList.propTypes = {
    books: PropTypes.array,
  };

  return (
    <Table striped bordered hover>
      <thead>
        <th className="sortable" style={{ textAlign: "center" }}>
          الكتاب
        </th>
        <th
          className="sortable"
          style={{ width: "100px", textAlign: "center" }}
        >
          السعر
        </th>
      </thead>
      <tbody>
        {props.books &&
          props.books.map((book) => (
            <tr key={book.id}>
              <td>
                <Link to={`/books/${book.id}`}>{book.title}</Link>
              </td>
              <td style={{ width: "100px", textAlign: "center" }}>
                {book.price.toFixed(3)}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default PersonBooksList;
