import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Home from "./pages/home/home";
import Login from "./pages/login";
import AuthProvider from "./AuthProvider";
import Books from "./pages/books/Books";
import BookDetails from "./pages/books/BookDetails";
import Categories from "./pages/categories/Categories";
import CategoryDetails from "./pages/categories/CategoryDetails";
import Users from "./pages/users/Users";
import BookEditor from "./pages/books/BookEditor";
import Authors from "./pages/authors/Authors";
import AuthorDetails from "./pages/authors/AuthorDetails";
import AuthorEditor from "./pages/authors/AuthorEditor";
import Series from "./pages/series/Series";
import SeriesDetails from "./pages/series/SeriesDetails";
import SeriesEditor from "./pages/series/SeriesEditor";
import Editors from "./pages/editors/Editors";
import Translators from "./pages/translators/Translators";
import EditorDetails from "./pages/editors/EditorDetails";
import Banners from "./pages/banners/Banners";
import BannerDetails from "./pages/banners/BannerDetails";
import BannerEditor from "./pages/banners/BannerEditor";
import EditorEditor from "./pages/editors/EditorEditor";
import TranslatorDetails from "./pages/translators/TranslatorDetails";
import TranslatorEditor from "./pages/translators/TranslatorEditor";
import CategoryEditor from "./pages/categories/CategoryEditor";
import UserDetails from "./pages/users/UserDetails";
import UserEditor from "./pages/users/UserEditor";
import Roles from "./pages/roles/Roles";
import RoleDetails from "./pages/roles/RoleDetails";
import RoleEditor from "./pages/roles/RoleEditor";
import Invoices from "./pages/invoices/Invoices";
import InvoiceDetails from "./pages/invoices/InvoiceDetails";
import Page404 from "./pages/errors/page404";
import Customers from "./pages/customers/Customers";
import CustomerDetails from "./pages/customers/CustomerDetails";
import CustomerEditor from "./pages/customers/CustomerEditor";
import { addObject } from "./Api";

const App = () => {
  // useEffect(() => {
  //   const data = {
  //     id: null,
  //     email: "sghaleb@meeshosoft.com",
  //     firstname: "Saleh",
  //     lastname: "Ghaleb",
  //     role_id: 1,
  //     active: true,
  //     username: "sghaleb",
  //     password: "s@lMad310394",
  //   };

  //   addObject("users", data);
  // });

  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/" element={<Home />} />
            </Route>
            <Route exact path="/dashboard" element={<PrivateRoute />}>
              <Route exact path="/dashboard" element={<Home />} />
            </Route>
            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/" element={<Home />} />
            </Route>
            <Route path="/categories" element={<PrivateRoute />}>
              <Route path="/categories" element={<Categories />} />
              <Route path="/categories/:id" element={<CategoryDetails />} />
              <Route path="/categories/edit/:id" element={<CategoryEditor />} />
            </Route>
            <Route path="/publishers" element={<PrivateRoute />}>
              <Route path="/publishers" element={<Series />} />
              <Route path="/publishers/:id" element={<SeriesDetails />} />
            </Route>
            <Route path="/series" element={<PrivateRoute />}>
              <Route path="/series" element={<Series />} />
              <Route path="/series/:id" element={<SeriesDetails />} />
              <Route path="/series/edit/:id" element={<SeriesEditor />} />
            </Route>
            <Route path="/books" element={<PrivateRoute />}>
              <Route path="/books" element={<Books />} />
              <Route path="/books/:id" element={<BookDetails />} />
              <Route path="/books/edit/:id" element={<BookEditor />} />
            </Route>
            <Route path="/authors" element={<PrivateRoute />}>
              <Route path="/authors" element={<Authors />} />
              <Route path="/authors/:id" element={<AuthorDetails />} />
              <Route path="/authors/edit/:id" element={<AuthorEditor />} />
            </Route>
            <Route path="/editors" element={<PrivateRoute />}>
              <Route path="/editors" element={<Editors />} />
              <Route path="/editors/:id" element={<EditorDetails />} />
              <Route path="/editors/edit/:id" element={<EditorEditor />} />
            </Route>
            <Route path="/translators" element={<PrivateRoute />}>
              <Route path="/translators" element={<Translators />} />
              <Route path="/translators/:id" element={<TranslatorDetails />} />
              <Route
                path="/translators/edit/:id"
                element={<TranslatorEditor />}
              />
            </Route>
            <Route path="/banners" element={<PrivateRoute />}>
              <Route path="/banners" element={<Banners />} />
              <Route path="/banners/:id" element={<BannerDetails />} />
              <Route path="/banners/edit/:id" element={<BannerEditor />} />
            </Route>
            <Route path="/invoices" element={<PrivateRoute />}>
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/invoices/:id" element={<InvoiceDetails />} />
            </Route>
            <Route path="/customers" element={<PrivateRoute />}>
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/:id" element={<CustomerDetails />} />
              <Route path="/customers/edit/:id" element={<CustomerEditor />} />
            </Route>
            <Route path="/users" element={<PrivateRoute />}>
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<UserDetails />} />
              <Route path="/users/edit/:id" element={<UserEditor />} />
            </Route>
            <Route path="/roles" element={<PrivateRoute />}>
              <Route path="/roles" element={<Roles />} />
              <Route path="/roles/:id" element={<RoleDetails />} />
              <Route path="/roles/edit/:id" element={<RoleEditor />} />
            </Route>
            <Route exact path="/login" element={<Login />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
};

export default App;
