import "../../App.css";
import React, { useState, useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import { useNavigate } from "react-router";
import PageHeader from "../../components/PageHeader";
import ActionButton from "../../components/ActionButton";
import DataGrid from "../../components/DataGrid";
import { deleteObject, fetchCollection } from "../../Api";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function Customers() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchCollection("customers", [], [])
      .then((result) => {
        setData(result);
        return result;
      })
      .catch(console.error);
  }, []);

  const columns = [
    {
      dataField: "id",
      text: "المعرف",
      sort: true,
      style: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      headerStyle: (colum, colIndex) => {
        return {
          width: "100px",
          minWidth: "100px",
          textAlign: "center",
        };
      },
    },
    {
      dataField: "firstname",
      text: "الاسم الأول",
      sort: true,
    },
    {
      dataField: "lastname",
      text: "الاسم الأخير",
      sort: true,
    },
    {
      dataField: "email",
      text: "البريد الإلكتروني",
      sort: true,
    },
  ];

  const handleDelete = (row) => {
    deleteObject("users", row.id)
      .then((result) => {
        if (result === 1) {
          window.location.reload();
        }
      })
      .catch(console.error);
  };

  return (
    <Layout>
      <PageHeader title="العملاء" />
      {data ? (
        <DataGrid
          data={data}
          columns={columns}
          onView={(row) => navigate(`/customers/${row.id}`)}
          canEdit={(row) => row.account_type === 0}
          onEdit={(row) => navigate(`/customers/edit/${row.id}`)}
          showDelete={false}
        />
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}
