import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { baseURL } from "./Api";
import axios from "axios";
import { AuthContext } from "./hooks";

const AuthProvider = (props) => {
  AuthProvider.propTypes = {
    children: PropTypes.array,
  };

  const [loading, setLoading] = useState(true);

  const [currentUser, _setCurrentUser] = useState(() => {
    // getting stored value
    const saved = JSON.parse(localStorage.getItem("user"));
    const initialValue = saved;
    return initialValue || null;
  });

  const setCurrentUser = (user) => {
    if (user) {
      // var base64Url = token.split(".")[1];
      // var base64 = base64Url.replace("-", "+").replace("_", "/");
      // var x = JSON.parse(window.atob(base64));

      _setCurrentUser(user);
    } else {
      localStorage.removeItem("user");
      _setCurrentUser(null);
    }
  };

  const [token, _setToken] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("token");
    const initialValue = saved;

    return initialValue || null;
  });

  const setToken = (token) => {
    _setToken(token);
    localStorage.removeItem("token");
  };

  useEffect(() => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [token, currentUser]);

  const login = async (username, password) => {
    try {
      let res = await axios.post(`${baseURL}/auth/login`, {
        username: username,
        password: password,
      });

      if (res.data) {
        setToken(res.data.token);
        setCurrentUser(res.data.user);
        return res.data;
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }

    return null;
  };

  const logout = () => {
    setToken(null);
    setCurrentUser(null);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const value = {
    token,
    currentUser,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
