import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

export default function DeleteDialog(props) {
  DeleteDialog.propTypes = {
    row: PropTypes.array,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    onConfirm: PropTypes.func,
  };

  const [visible, setVisible] = useState(props.visible);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <Modal show={visible} onHide={() => props.onHide && props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>تحذير</Modal.Title>
      </Modal.Header>
      <Modal.Body>هل أنت متأكد من الحذف؟</Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => props.onConfirm && props.onConfirm(props.row)}
        >
          حـذف
        </Button>
        <Button
          variant="secondary"
          onClick={() => props.onHide && props.onHide()}
        >
          إلغاء الأمر
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
