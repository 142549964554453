import React from "react";
import PropTypes from "prop-types";

const ActionButton = (props) => {
  ActionButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
  };

  return (
    <button className={props.className} onClick={props.onClick}>
      {props.title}
    </button>
  );
};

export default ActionButton;
