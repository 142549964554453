import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "./hooks";

const PrivateRoute = ({ children, ...rest }) => {
  PrivateRoute.propTypes = {
    children: PropTypes.array,
  };

  const { currentUser } = useAuth();

  return currentUser ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
