import "react-widgets/styles.css";
import "react-select-search/style.css";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Layout from "../../components/layouts/Layout";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  fetchObject,
  addObject,
  updateObject,
  fetchOtherCategories,
} from "../../Api";

export default function CategoryEditor() {
  const { id } = useParams();

  const navigate = useNavigate();

  const nameRef = useRef();
  const parentRef = useRef();
  const activeRef = useRef();

  const [editMode, setEditMode] = useState(false);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", category.id);
    formData.append("name", nameRef.current.value);
    formData.append("parent", parentRef.current.value);
    formData.append("active", activeRef.current.checked);

    try {
      const response = editMode
        ? await updateObject("categories", formData)
        : await addObject("categories", formData);

      if (response != null) {
        navigate(`/categories/${response.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchOtherCategories(id).then((result) => {
      setCategories(result);

      if (id && parseInt(id) > 0) {
        setEditMode(true);
        fetchObject("categories", id).then((result) => {
          setCategory(result.data);
          return result;
        });
      } else {
        setCategory({ id: null, name: null, parent: null, active: true });
      }
    });
  });

  return category ? (
    <>
      <Layout>
        <div className="page-header">
          <h1>{editMode ? "تعديل" : "إضافة"} تصنيف</h1>
        </div>
        <Container>
          <Form onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={category.id} id="id" />
            <Form.Group className="mb-3" controlId="formCategoryName">
              <Form.Label>الاسم</Form.Label>
              <Form.Control
                type="text"
                defaultValue={category.name}
                ref={nameRef}
                placeholder="أدخل الاسم"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCategoryParent">
              <Form.Label>التصنيف الرئيسي</Form.Label>
              <Form.Select ref={parentRef}>
                <option key="0" value="0">
                  اختر التصنيف
                </option>
                {categories.map((o) => {
                  if (o.id === category.parent_id) {
                    return (
                      <option key={o.id} value={o.id} selected="selected">
                        {o.name}
                      </option>
                    );
                  }

                  return (
                    <option key={o.id} value={o.id}>
                      {o.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCategoryActive">
              <Form.Label>مفعل</Form.Label>
              <Form.Check
                type="checkbox"
                ref={activeRef}
                defaultChecked={category.active}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="ms-3">
              حـفـظ
            </Button>
            <Button variant="secondary" onClick={() => navigate(-1)}>
              إلغاء الأمر
            </Button>
          </Form>
          <p></p>
          <p></p>
        </Container>
      </Layout>
    </>
  ) : (
    <></>
  );
}
