import "react-widgets/styles.css";
import "react-select-search/style.css";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Layout from "../../components/layouts/Layout";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  publicURL,
  fetchObject,
  addObject,
  updateObject,
  fetchCollection,
} from "../../Api";
import SearchableList from "../../components/SearchableList";
import { Col, Row } from "react-bootstrap";
import PageHeader from "../../components/PageHeader";

export default function BookEditor() {
  const { id } = useParams();

  const navigate = useNavigate();

  const issueYearRef = useRef();
  const seriesRef = useRef();
  const titleRef = useRef();
  const subtitleRef = useRef();
  const descriptionRef = useRef();
  const priceRef = useRef();
  const discountRef = useRef();
  const activeRef = useRef();
  const coverRef = useRef();
  const fileNameRef = useRef();

  const [series, setSeries] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [editors, setEditors] = useState([]);
  const [translators, setTranslators] = useState([]);

  const [bookAuthors, setBookAuthors] = useState([]);
  const [bookEditors, setBookEditors] = useState([]);
  const [bookTranslators, setBookTranslators] = useState([]);

  const [file, setFile] = useState(null);
  const [cover, setCover] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [coverPreview, setCoverPreview] = useState("");

  const [book, setBook] = useState(null);

  const handleAuthorSelect = (e) => {
    setBookAuthors(e);
  };

  const handleEditorSelect = (e) => {
    setBookEditors(e);
  };

  const handleTranslatorSelect = (e) => {
    setBookTranslators(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", book.id);
    formData.append("series_id", seriesRef.current.value);
    formData.append("issue_year", issueYearRef.current.value);
    formData.append("title", titleRef.current.value);
    formData.append("subtitle", subtitleRef.current.value);
    formData.append("description", descriptionRef.current.value);
    formData.append("price", parseFloat(priceRef.current.value));
    formData.append(
      "discount",
      discountRef.current.value ? parseFloat(discountRef.current.value) : 0.0
    );
    formData.append("active", activeRef.current.checked);
    formData.append("authors", JSON.stringify(bookAuthors));
    formData.append("editors", JSON.stringify(bookEditors));
    formData.append("translators", JSON.stringify(bookTranslators));

    if (editMode) {
      if (file) {
        formData.append("file", file, file.name);
      } else {
        formData.append("file", null);
      }

      if (cover) {
        formData.append("cover", cover, cover.name);
      } else {
        formData.append("cover", null);
      }
    } else {
      formData.append("file", file, file.name);
      formData.append("cover", cover, cover.name);
    }

    try {
      const response = editMode
        ? await updateObject("books", formData)
        : await addObject("books", formData);

      if (response != null) {
        navigate(`/books/${response.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleCoverChange = (e) => {
    if (e.target.files[0]) {
      setCover(e.target.files[0]);
      console.log(URL.createObjectURL(e.target.files[0]));
      setCoverPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    fetchCollection("people").then((res) => {
      setAuthors(res.authors);
      setEditors(res.editors);
      setTranslators(res.translators);

      fetchCollection("series")
        .then((result) => {
          setSeries(result);

          setEditMode(id && parseInt(id) > 0);

          if (id && parseInt(id) > 0) {
            fetchObject("books", id).then((result) => {
              setBook(result.data);
              setBookAuthors(result.data.authors);
              setBookEditors(result.data.editors);
              setBookTranslators(result.data.translators);

              setCoverPreview(result.data.images[0].url);
              return result;
            });
          } else {
            setBook({ id: null, active: true, authors: [] });
            setCoverPreview(`${publicURL}/covers/book-cover.png`);
          }

          return null;
        })
        .catch(console.error);
    });
  }, [id]);

  return book ? (
    <>
      <Layout>
        <PageHeader title={`${editMode ? "تعديل" : "إضافة"} كتاب`} />
        <Container>
          <table style={{ width: "100%" }}>
            <tr>
              <td>
                <Form onSubmit={handleSubmit}>
                  <input type="hidden" name="id" value={book.id} id="id" />
                  <Form.Group className="mb-3" controlId="formBookSeries">
                    <Form.Label>السلسة</Form.Label>
                    <Form.Select
                      ref={seriesRef}
                      defaultValue={book && book.series_id}
                    >
                      <option key="0" value="0">
                        اختر السلسة
                      </option>
                      {series.map((o) => {
                        return (
                          <option key={o.id} value={o.id}>
                            {o.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBookTitle">
                    <Form.Label>العنوان</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={book.title}
                      ref={titleRef}
                      placeholder="Enter title"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBookSubTitle">
                    <Form.Label>العنوان الفرعي</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={book.subtitle}
                      ref={subtitleRef}
                      placeholder="Enter title"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBookSubTitle">
                    <Form.Label>سنة النشر</Form.Label>
                    <Form.Control
                      type="number"
                      defaultValue={book.issue_year}
                      ref={issueYearRef}
                      placeholder="Enter issue year"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    style={{ position: "relative" }}
                    controlId="formBookCover"
                  >
                    <Form.Label>الغلاف</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".png,.jpj,.jpeg"
                      name="cover"
                      placeholder="Select cover image"
                      ref={coverRef}
                      onChange={handleCoverChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBookFile">
                    <Form.Label>المحتوى</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".pdf,.epub"
                      placeholder="Select ebook file"
                      ref={fileNameRef}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBookDescription">
                    <Form.Label>الوصف</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={12}
                      defaultValue={book.description}
                      ref={descriptionRef}
                      placeholder="Enter description"
                    />
                  </Form.Group>
                  <Row>
                    <Col className="col round-rect-border ms-3">
                      <Form.Group className="mb-3" controlId="formBookAuthors">
                        <Form.Label>تأليف</Form.Label>
                        <SearchableList
                          key="authors"
                          options={authors}
                          value={book.authors}
                          onChange={handleAuthorSelect}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col round-rect-border ms-3">
                      <Form.Group className="mb-3" controlId="formBookEditors">
                        <Form.Label>تحقيق</Form.Label>
                        <SearchableList
                          key="editors"
                          options={editors}
                          value={book.editors}
                          onChange={handleEditorSelect}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col round-rect-border">
                      <Form.Group
                        className="mb-3"
                        controlId="formBookTranslators"
                      >
                        <Form.Label>ترجمة</Form.Label>
                        <SearchableList
                          key="translators"
                          options={translators}
                          value={book.translators}
                          onChange={handleTranslatorSelect}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mt-3 mb-3" controlId="formBookPrice">
                    <Form.Label>السعر</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      step="0.005"
                      defaultValue={book.price}
                      ref={priceRef}
                      placeholder="Enter price"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mt-3 mb-3"
                    controlId="formBookDiscount"
                  >
                    <Form.Label>الخصم</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      step="1"
                      defaultValue={book.discount}
                      ref={discountRef}
                      placeholder="Enter discount"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBookActive">
                    <Form.Label>مفعل</Form.Label>
                    <Form.Check
                      type="checkbox"
                      ref={activeRef}
                      defaultChecked={book.active}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="ms-3">
                    حـفـظ
                  </Button>
                  <Button variant="secondary" onClick={() => navigate(-1)}>
                    إلغاء الأمر
                  </Button>
                </Form>
              </td>
              <td
                style={{
                  width: "150px",
                  textAlign: "center",
                  verticalAlign: "top",
                }}
              >
                <img src={coverPreview} alt="" className="cover-preview" />
              </td>
            </tr>
          </table>
          <p></p>
          <p></p>
        </Container>
      </Layout>
    </>
  ) : (
    <></>
  );
}
