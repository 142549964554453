import "../../App.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import PageHeader from "../../components/PageHeader";
import ActionButton from "../../components/ActionButton";
import DataGrid from "../../components/DataGrid";
import { deleteObject, fetchCollection } from "../../Api";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function Editors() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchCollection("banners")
      .then((result) => {
        setData(result);
        return result;
      })
      .catch(console.error);
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "الاسم",
      sort: true,
    },
    {
      dataField: "banner",
      text: "الإعلان",
      sort: true,
    },
    {
      dataField: "link",
      text: "الرابط",
      sort: true,
    },
    {
      dataField: "active",
      text: "مفعل",
      sort: true,
    },
  ];

  const handleDelete = (row) => {
    deleteObject("banners", row.id)
      .then((result) => {
        if (result === 1) {
          window.location.reload();
        }
      })
      .catch(console.error);
  };

  return (
    <Layout>
      <PageHeader title="الإعلانات">
        <ActionButton
          title="إضافة"
          className="action-button green-button"
          onClick={() => navigate("/banners/edit/0")}
        />
      </PageHeader>
      {data ? (
        <DataGrid
          data={data}
          columns={columns}
          onView={(row) => navigate(`/banners/${row.id}`)}
          onEdit={(row) => navigate(`/banners/edit/${row.id}`)}
          onDelete={(row) => handleDelete(row)}
        />
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}
