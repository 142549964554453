import "../../App.css";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import PageHeader from "../../components/PageHeader";
import DataGrid from "../../components/DataGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import { fetchCollection } from "../../Api";
import { useNavigate } from "react-router";

const Invoices = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const columns = [
    {
      dataField: "id",
      text: "المعرف",
      sort: true,
      style: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      headerStyle: (colum, colIndex) => {
        return {
          width: "100px",
          minWidth: "100px",
          textAlign: "center",
        };
      },
    },
    {
      dataField: "invoiceNo",
      text: "رقم الفاتورة",
      sort: true,
    },
    {
      dataField: "date",
      text: "التاريخ والوقت",
      sort: true,
      formatter: (data, row) => {
        let date = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        return date.format(new Date(data));
      },
    },
    {
      dataField: "customer.firstname",
      text: "العميل",
      sort: true,
      formatter: (data, row) => {
        return data + " " + row.customer.lastname;
      },
    },
    {
      dataField: "total",
      text: "المجموع",
      sort: true,
      formatter: (data, row) => parseFloat(data).toFixed(3),
    },
  ];

  useEffect(() => {
    fetchCollection("orders")
      .then((result) => {
        setData(result);
        return result;
      })
      .catch(console.error);
  }, []);

  return (
    <Layout>
      <PageHeader title="المبيعات" />
      {data ? (
        <DataGrid
          data={data}
          columns={columns}
          onView={(row) => navigate(`/invoices/${row.id}`)}
          showEdit={false}
          showDelete={false}
        />
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
};

export default Invoices;
