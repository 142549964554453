import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faEye, faClose } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import DeleteDialog from "./DeleteDialog";

export default function DataGrid(props) {
  DataGrid.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    actions: PropTypes.array,
    showView: PropTypes.bool,
    showEdit: PropTypes.bool,
    showDelete: PropTypes.bool,
    onView: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    canEdit: PropTypes.func,
    canDelete: PropTypes.func,
    actionsPaneWidth: PropTypes.number,
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  library.add(faEdit, faEye, faClose);

  const handleDeleteConfirmation = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleDelete = () => {
    props.onDelete && props.onDelete(selectedRow);
  };

  const checkCanEdit = (row) => {
    return props.canEdit ? props.canEdit(row) : true;
  };

  const checkCanDelete = (row) => {
    return props.canEdit ? props.canDelete(row) : true;
  };

  const actionsFormatter = (data, row) => {
    const standardActions = [
      {
        id: 1,
        title: "عرض",
        icon: "eye",
        visible: props.showView,
        enabled: true,
        onClick: () => props.onView(row),
      },
      {
        id: 2,
        title: "تعديل",
        icon: "edit",
        visible: props.showEdit,
        enabled: checkCanEdit(row),
        onClick: () => props.onEdit(row),
      },
      {
        id: 3,
        title: "حذف",
        icon: "close",
        visible: props.showDelete,
        enabled: checkCanDelete(row),
        onClick: () => handleDeleteConfirmation(row),
      },
    ];

    const actions = [...standardActions, ...props.actions];

    return (
      <>
        {actions.map((action) => {
          return action.visible ? (
            action.enabled ? (
              <Button
                key={action.id}
                variant="link"
                className="icon-button"
                title={action.title}
                onClick={action.onClick}
              >
                <FontAwesomeIcon icon={action.icon} />
              </Button>
            ) : (
              <Button
                key={action.id}
                variant="link"
                className="icon-button disabled"
              >
                <FontAwesomeIcon icon={action.icon} />
              </Button>
            )
          ) : (
            <></>
          );
        })}
      </>
    );
  };

  const actionsColumn = {
    isDummyField: true,
    dataField: "actions_id",
    text: "",
    headerStyle: (colum, colIndex) => {
      return {
        width: `${props.actionsPaneWidth}px`,
        minWidth: `${props.actionsPaneWidth}px`,
        maxWidth: `${props.actionsPaneWidth}px`,
        textAlign: "center",
      };
    },
    formatter: actionsFormatter,
  };

  const [columns] = useState(() => {
    if (props.columns && props.columns.indexOf(actionsColumn) === -1) {
      props.columns.push(actionsColumn);
    }

    return props.columns;
  });

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={props.data}
        columns={columns}
        striped={true}
        bordered={true}
        hover={true}
        condensed={true}
        bootstrap4={true}
        pagination={paginationFactory()}
        noDataIndication={() => (
          <div style={{ textAlign: "center" }}>لا توجد بيانات</div>
        )}
      />
      <DeleteDialog
        visible={showModal}
        row={selectedRow}
        onHide={() => setShowModal(false)}
        onConfirm={handleDelete}
      />
    </>
  );
}

DataGrid.defaultProps = {
  showView: true,
  showEdit: true,
  showDelete: true,
  onView: null,
  canEdit: () => true,
  canDelete: () => true,
  actions: [],
  actionsPaneWidth: 120,
};
