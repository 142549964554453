import "react-widgets/styles.css";
import "../SearchableList.css";
import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const SearchableList = (props) => {
  SearchableList.propTypes = {
    key: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.object,
  };

  const queryRef = useRef();
  const selectRef = useRef();

  const [items, setItems] = useState(
    props.value
      ? props.options.filter((e) => {
          return !props.value.find((o) => o.name === e.name);
        })
      : props.options
  );

  const [selectedItems, setSelectedItems] = useState(props.value);
  const [visible, setVisible] = useState(false);

  const handleQueryChange = (e) => {
    const query = queryRef.current.value;
    let filteredItems = items;

    if (query !== "") {
      filteredItems = items.filter((e) => {
        return e.name.startsWith(query);
      });
    }

    setItems(filteredItems);
  };

  const handleRemove = (id, name) => {
    const filteredItems = selectedItems.filter((e) => {
      return e.id !== id;
    });

    setSelectedItems(filteredItems);

    if (typeof props.onChange === "function") {
      props.onChange(filteredItems);
    }

    if (items === null) {
      setItems([{ id: id, name: name }]);
    } else {
      setItems([...items, { id: id, name: name }]);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    setVisible(false);

    let values = Array.from(
      selectRef.current.selectedOptions,
      (option) => option
    );
    let selectedObjects = [];

    values.map((item) => {
      selectedObjects.push({ id: parseInt(item.value), name: item.text });
      return selectedObjects;
    });

    if (selectedItems) {
      setSelectedItems([...selectedItems, ...selectedObjects]);
      if (typeof props.onChange === "function") {
        props.onChange([...selectedItems, ...selectedObjects]);
      }
    } else {
      setSelectedItems(selectedObjects);
      if (typeof props.onChange === "function") {
        props.onChange(selectedObjects);
      }
    }

    setItems(
      items.filter((e) => {
        return !selectedObjects.find((o) => o.name === e.name);
      })
    );
  };

  return (
    <>
      <div className="sl-container" style={{ minHeight: "40px" }}>
        {selectedItems &&
          selectedItems.map((e) => {
            return (
              <div key={e.id} className="sl-widget">
                {e.name}
                <FontAwesomeIcon
                  icon={faClose}
                  className="sl-remove"
                  onClick={() => handleRemove(e.id, e.name)}
                  title="إزالة"
                />
              </div>
            );
          })}
        <FontAwesomeIcon
          icon={faAdd}
          className="sl-add"
          onClick={() => setVisible(true)}
          title="إضافة"
        />
      </div>

      <Modal show={visible} onHide={() => setVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>إضافة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="text"
              ref={queryRef}
              onChange={handleQueryChange}
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <select
              size="6"
              multiple={true}
              style={{ width: "100%" }}
              ref={selectRef}
            >
              {items
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((e) => {
                  return (
                    <option key={e.id} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAdd}>
            إضافة
          </Button>
          <Button variant="secondary" onClick={() => setVisible(false)}>
            الغاء الأمر
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchableList;
