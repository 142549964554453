import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../hooks";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";

const Login = () => {
  const navigate = useNavigate();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const data = await login(
        usernameRef.current.value,
        passwordRef.current.value
      );

      if (data) {
        navigate("/dashboard");
      } else {
        setError("بيانات دخول خاطئة");
      }
    } catch (e) {
      setError("حدث خطأ");
    }

    setLoading(false);
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">
              <img src="/enara-logo.png" alt="" style={{ maxWidth: "100px" }} />
            </h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email" className="wrapper">
                <Form.Label>اسم المستخدم</Form.Label>
                <Form.Control type="username" ref={usernameRef} required />
              </Form.Group>
              <Form.Group id="password" className="wrapper">
                <Form.Label>كلمة المرور</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Button disabled={loading} className="w-100 mt-4" type="submit">
                تسجيل الدخول
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default Login;
