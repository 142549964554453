import "react-widgets/styles.css";
import "react-select-search/style.css";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Layout from "../../components/layouts/Layout";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  addObject,
  fetchCollection,
  fetchObject,
  updateObject,
} from "../../Api";
import PageHeader from "../../components/PageHeader";

const UserEditor = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const emailRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const roleRef = useRef();
  const activeRef = useRef();

  const [user, setUser] = useState({
    id: null,
    email: null,
    password: null,
    firsname: null,
    lastname: null,
    active: true,
  });
  const [roles, setRoles] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // e.stopPropagation();

    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity()) {
      const data = {
        id: user.id,
        email: emailRef.current.value,
        firstname: firstnameRef.current.value,
        lastname: lastnameRef.current.value,
        role_id: parseInt(roleRef.current.selectedOptions[0].value),
        active: activeRef.current.checked,
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      };

      try {
        const response = editMode
          ? await updateObject("users", data)
          : await addObject("users", data);

        if (response != null) {
          navigate(`/users/${response.id}`);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    fetchCollection("roles", [])
      .then((roles) => {
        setRoles(roles);
        if (id && parseInt(id) > 0) {
          setEditMode(true);
          fetchObject("users", id)
            .then((result) => {
              setUser(result.data);
              roleRef.current.value = result.data.role.id;
              return result.data;
            })
            .catch(console.error);
        }
      })
      .catch(console.error);
  }, []);

  const handlePasswordInput = (event) => {
    const target = event.target;
    const password_pattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!\[\]{}()\\/`'"@#$<>%^&*=?.+`~\-__,;:])[a-zA-Z0-9!\[\]{}()\\/`'"@#$<>%^&*.=?+~\-_,;:]{8,32}$/;

    if (editMode && target.value.length === 0) {
      target.setCustomValidity("");
      return;
    }

    if (!password_pattern.test(target.value)) {
      target.setCustomValidity("كلمة المرور غير مقبولة");
      target.reportValidity();
    } else {
      target.setCustomValidity("");
    }
  };

  return user ? (
    <Layout>
      <PageHeader title={`${editMode ? "تعديل" : "إضافة"} مستخدم`} />
      <Container>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <input type="hidden" name="id" value={user.id || ""} id="id" />
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="formUserUsername">
              <Form.Label>اسم المستخدم</Form.Label>
              <Form.Control
                type="text"
                required={true}
                readOnly={editMode}
                defaultValue={user.username || ""}
                ref={usernameRef}
                placeholder="أدخل اسم المستخدم"
              />
              <Form.Control.Feedback type="invalid">
                يرجى إدخال اسم المستخدم
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formUserEmail">
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                type="email"
                required={true}
                defaultValue={user.email || ""}
                ref={emailRef}
                placeholder="أدخل البريد الإلكتروني"
              />
              <Form.Control.Feedback type="invalid">
                يرجى إدخال البريد الإلكتروني
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              controlId="formUserPassword"
              onInput={handlePasswordInput}
              autoComplete="new-password"
            >
              <Form.Label>
                كلمة المرور
                <small className="form-text" style={{ direction: "ltr" }}>
                  &nbsp;(Must be 8-32 characters long, must contain special
                  characters, numbers, lower and upper letters only.)
                </small>
              </Form.Label>
              <Form.Control
                type="password"
                required={!editMode}
                minLength={8}
                maxLength={32}
                defaultValue={user.password || ""}
                ref={passwordRef}
                placeholder="ادخل كلمة المرور"
              />
              <Form.Text>
                {editMode && <span>Enter only if you want to change it</span>}
              </Form.Text>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="formUserFirstName">
              <Form.Label>الاسم الأول</Form.Label>
              <Form.Control
                type="text"
                required={true}
                minLength={2}
                maxLength={40}
                defaultValue={user.firstname || ""}
                ref={firstnameRef}
                placeholder="أدخل الاسم الأول"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formUserLastName">
              <Form.Label>اسم العائلة</Form.Label>
              <Form.Control
                type="text"
                required={true}
                minLength={2}
                maxLength={40}
                defaultValue={user.lastname || ""}
                ref={lastnameRef}
                placeholder="أدخل اسم العائلة"
              />
            </Form.Group>
          </Row>
          <Form.Group as={Col} md="3" className="mb-3" controlId="formUserRole">
            <Form.Label>الوظيفة</Form.Label>
            <Form.Select
              ref={roleRef}
              defaultValue={user.role ? user.role.id : 0}
            >
              <option key="0" value="0">
                اختر الوظيفة
              </option>
              {roles.map((o) => {
                return (
                  <option key={o.id} value={o.id}>
                    {o.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formUserActive">
            <Form.Label>مفعل</Form.Label>
            <Form.Check
              type="checkbox"
              ref={activeRef}
              defaultChecked={user.active || false}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="ms-3">
            حـفـظ
          </Button>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            إلغاء الأمر
          </Button>
        </Form>
        <p></p>
        <p></p>
      </Container>
    </Layout>
  ) : (
    <></>
  );
};

export default UserEditor;
