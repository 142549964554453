import "bootstrap/dist/css/bootstrap.css";
import "react-widgets/styles.css";
import "react-select-search/style.css";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Layout from "../../components/layouts/Layout";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetchObject, addObject, updateObject } from "../../Api";
import PageHeader from "../../components/PageHeader";

const BannerEditor = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const nameRef = useRef();
  const bannerRef = useRef();
  const linkRef = useRef();
  const activeRef = useRef();

  const [file, setFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [banner, setBanner] = useState(null);
  const [bannerPreview, setBannerPreview] = useState("");

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setBannerPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", banner.id);
    formData.append("name", nameRef.current.value);
    formData.append("link", linkRef.current.value);
    formData.append("active", activeRef.current.checked);

    if (editMode) {
      if (file) {
        formData.append("banner", file, file.name);
      } else {
        formData.append("banner", null);
      }
    } else {
      formData.append("banner", file, file.name);
    }

    try {
      const response = editMode
        ? await updateObject("banners", formData)
        : await addObject("banners", formData);

      if (response != null) {
        navigate(`/banners/${response.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id && parseInt(id) > 0) {
      setEditMode(true);
      fetchObject("banners", id).then((result) => {
        setBanner(result.data);
        setBannerPreview(result.data.banner);
        return result.data;
      });
    } else {
      setBanner({ id: null, link: null, active: true });
    }
  }, [id]);

  return banner ? (
    <Layout>
      <PageHeader title={`${editMode ? "تعديل" : "إضافة"} إعلان`} />
      <Container>
        <Form onSubmit={handleSubmit}>
          <input type="hidden" name="id" value={banner.id} id="id" />
          <Form.Group className="mb-3" controlId="formBookTitle">
            <Form.Label>الاسم</Form.Label>
            <Form.Control
              type="text"
              defaultValue={banner.name}
              ref={nameRef}
              placeholder="أدخل الاسم"
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            style={{ position: "relative" }}
            controlId="formBookCover"
          >
            <Form.Label>الإعلان</Form.Label>
            <Form.Control
              type="file"
              name="banner"
              placeholder="اختر الإعلان"
              ref={bannerRef}
              onChange={handleFileChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <Form.Label>الرابط</Form.Label>
            <Form.Control
              type="text"
              defaultValue={banner.link}
              ref={linkRef}
              placeholder="أدخل الرابط"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBookActive">
            <Form.Label>مفعل</Form.Label>
            <Form.Check
              type="checkbox"
              ref={activeRef}
              defaultChecked={banner.active}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="ms-3">
            حـفـظ
          </Button>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            إلغاء الأمر
          </Button>
        </Form>
        <div style={{ textAlign: "center" }}>
          <img src={bannerPreview} alt="" style={{ width: 600, height: 338 }} />
        </div>
      </Container>
    </Layout>
  ) : (
    <></>
  );
};

export default BannerEditor;
