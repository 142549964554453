import axios from "axios";

export const baseURL = process.env.REACT_APP_BACKEND_API_URL;
export const publicURL = process.env.REACT_APP_BACKEND_PUBLIC_URL;

const token = localStorage.getItem("token");

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export async function fetchCollection(path, args = [], def = null) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  var arr = "";

  if (args.length > 0) {
    args.forEach((element) => {
      arr += "/" + element;
    });
  }

  try {
    const response = await axios.get(`${baseURL}/${path}${arr}`, config);
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    alert(e.message);
    console.log(e);
  }

  return def;
}

export async function fetchObject(path, params = "") {
  return await axios.get(`${baseURL}/${path}/${params}`, config);
}

export async function addObject(path, obj) {
  const response = await axios.post(`${baseURL}/${path}`, obj, config);

  if (response.status === 200) {
    return response.data;
  }

  return null;
}

export async function updateObject(path, obj) {
  const response = await axios.put(`${baseURL}/${path}`, obj, config);
  if (response.status === 200) {
    return response.data;
  }

  return null;
}

export async function deleteObject(path, params) {
  const response = await axios.delete(`${baseURL}/${path}/${params}`, config);
  if (response.status === 200) {
    return response.data;
  }

  return null;
}

export async function fetchOtherCategories(id) {
  const response = await axios.get(`${baseURL}/categories/other/${id}`, config);
  if (response.status === 200) {
    return response.data;
  }

  return [];
}
